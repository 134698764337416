import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [stage, setStage] = useState('captcha');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showWait, setShowWait] = useState(false);

  const nextStage = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setShowWait(true);
      setTimeout(() => {
        setShowWait(false);
        setStage(prevStage => {
          switch(prevStage) {
            case 'captcha': return 'wordGuess1';
            case 'wordGuess1': return 'wordGuess2';
            case 'wordGuess2': return 'wordGuess3';
            case 'wordGuess3': return 'birthdayMessage';
            default: return 'end';
          }
        });
      }, 2000);
    }, 2000);
  };

  const renderStage = () => {
    if (showSuccess) return <SuccessCard />;
    if (showWait) return <WaitCard />;
    switch(stage) {
      case 'captcha':
        return <Captcha onComplete={nextStage} />;
      case 'wordGuess1':
        return <WordGuess 
          word="გივი" 
          clue=" ეს სიტყვა ძველ ბერძნულ მითოლოგიაში არაფერს ნიშნავს, არც სკანდინავიურ მითოლოგიაში დაუდევს სათავე. ამბობენ რომ მხოლოდ მაშინ გამოიყენება, როდესაც ვინმეს სტუმრობ და გინდა გააგებინო, ვინ ხარ, მაგრამ პრაქტიკაში ამ სიტყვას ძალიან განსხვავებულად და კონტექსტის შესაბამისად ვიყენებთ."
          onComplete={nextStage} 
        />;
      case 'wordGuess2':
        return <WordGuess 
          word="ელაკო" 
          clue="ამბობენ რომ ყველა გზა რომამდე მიდის, მაგრამ თუ ჩინურ სიბრძნეებს ვენდობით რომი არა ისაო. დღევანდელ მსოფლიოშიც ძირეულმა მრწამსობრივმა და ტექნოლოგიურმა ცვლილებებმა, ზემოხსენებულ შეხედულებას დიდი დარტყმა მიაყენა და პოპულარობითაც ვეღარ სარგებლობს. დღესდღეობით, თუ ქუჩაში გავაჩერებთ რენდომ მოქალაქეს ის ამის ნაცვლად გეტყვის , ყველა გზა რომში კი არა J-ში მიდის, დაასახელეთ J."
          onComplete={nextStage} 
        />;
      case 'wordGuess3':
        return <WordGuess 
          word="ჩუმლაყი" 
          clue="მსოფლიოს ყველა კუთხეში არსებობს ქალაქი ან სოფელი ან დასახლება, რომელშიც ხალხი ერთმანეთს ხვდება პაემნებზე, გასაცნობად თუ გასართობად.მთავარ სიყვარულის ქალაქად მსოფლიოში პარიზია მიჩნეული, მაგრამ არსებობს სხვა სიყვარული ქალაქებიც, ფლორენცია, ვენეცია, ბრიუგე სიღნაღი და ა.შ, თუმცა გადმოცემის თანახმად სიყვარულის ქალაქებმა სათავე სწორედ ამ დასახლებიდან აიღეს. დაასახელეთ დასახლებული პუნქტი."
          onComplete={nextStage} 
        />;
      case 'birthdayMessage':
        return <BirthdayMessage />;
      default:
        return <div>დასრულდა</div>;
    }
  };

  return (
    <div className="App">
      {renderStage()}
    </div>
  );
}

function Captcha({ onComplete }) {
  const [selected, setSelected] = useState([]);

  const handleImageClick = (index) => {
    const newSelected = selected.includes(index)
      ? selected.filter(i => i !== index)
      : [...selected, index];
    setSelected(newSelected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onComplete();
  };

  return (
    <div className="card captcha">
      <h2>human verification - აირჩიეთ ყველა ფოტო რომელზეც ხაჭაპურია</h2>
      <div className="image-grid">
        {[...Array(9)].map((_, index) => (
          <img 
            key={index}
            src={`/images/captcha_${index + 1}.jpg`}
            alt={`Captcha image ${index + 1}`}
            onClick={() => handleImageClick(index)}
            className={selected.includes(index) ? 'selected' : ''}
          />
        ))}
      </div>
      <button onClick={handleSubmit}>დადასტურება</button>
    </div>
  );
}

function WordGuess({ word, clue, onComplete }) {
  const [guess, setGuess] = useState(Array(word.length).fill(''));
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, word.length);
  }, [word]);

  const handleChange = (index, value) => {
    const newGuess = [...guess];
    newGuess[index] = value;
    setGuess(newGuess);

    if (value && index < word.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !guess[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (guess.join('').toLowerCase() === word.toLowerCase()) {
      onComplete();
    } else {
      alert('სისხლიიი, თავიდან სცადეე');
    }
  };

  return (
    <div className="card word-guess">
      <p>{clue}</p>
      <form onSubmit={handleSubmit}>
        <div className="letter-inputs">
          {word.split('').map((_, index) => (
            <input 
              key={index}
              ref={el => inputRefs.current[index] = el}
              type="text" 
              maxLength="1"
              value={guess[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
            />
          ))}
        </div>
        <button type="submit">ვნახოთ აპა რაარი</button>
      </form>
    </div>
  );
}

function SuccessCard() {
  return (
    <div className="card success">
      <h2>საააღოლ ბრაატ !</h2>
    </div>
  );
}

function WaitCard() {
  return (
    <div className="card wait">
      <h2>დაელოდეთ შემდეგ მითითებას</h2>
    </div>
  );
}

function BirthdayMessage() {
  const messages = [
    'თიკოლოზ', 'უადამიანესო', 'უმეგობრესო', 'უთბილესო', 'უ', 
    'გილოცავ', 'დაბადების', 'დღეს', 'გყვარობ', 'და', 'გხაჭაპურობ',
    'ჰეე თავში ნუ აგივარდება ახლა'
  ];
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showGif, setShowGif] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessage((prev) => {
        if (prev === messages.length - 1) {
          clearInterval(timer);
          setShowGif(true);
          return prev;
        }
        return prev + 1;
      });
    }, 2000);
    return () => clearInterval(timer);
  }, [messages.length]);

  return (
    <div className="birthday-message">
      {!showGif ? (
        <div className="card message-card">
          {messages[currentMessage]}
        </div>
      ) : (
        <div className="card gif-card">
          <img 
            src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExaTR1cWhmYmRwMmk2Zm90MWF1ZnNkMHFranhtYXUwcWtrb2lmajI5MCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9dg/bq1PRO9CLPHmURBvv2/giphy.gif" 
            alt="Funny celebration gif" 
          />
          <p>დაბადების დღეს გილოცავ✨</p>
        </div>
      )}
    </div>
  );
}

export default App;